import {
  Box,
  useTheme,
  Button,
  Typography,
  Select,
  MenuItem,
  TextField,
  Paper,
} from "@mui/material";
import { tokens } from "../../../theme";
import { useState, useEffect } from "react";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import useMediaQuery from "@mui/material/useMediaQuery";
import * as XLSX from "xlsx";
import dayjs from "dayjs";
import useAxiosPrivate from "../../../api/useAxiosPrivate";
import { styled } from "@mui/material/styles";
import PieChartReport from "../../../components/PieChartReport";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

const ReccuringClientsReport = () => {
  const axiosPrivate = useAxiosPrivate();
  const isNonMobile = useMediaQuery("(min-width:900px)");
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  let USDollar = new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  const [loadingData, setLoadingData] = useState(true);

  const [report, setReport] = useState([]);
  const [stores, setStores] = useState([]);
  const [period, setPeriod] = useState("WEEK");
  const [selectedStore, setSelectedStore] = useState({});

  const [pieDataAmount, setPieDataAmout] = useState([]);
  const [pieDataQuantity, setPieDataQuantity] = useState([]);
  const [pieDataProfit, setPieDataProfit] = useState([]);
  const periodOptions = [
    { id: 1, key: 1, label: "Últimos 7 Dias", value: "WEEK" },
    { id: 2, key: 2, label: "Últimos 30 Dias", value: "MONTH" },
    { id: 3, key: 3, label: "Últimos 90 Dias", value: "QUARTER" },
  ];

  useEffect(() => {
    const getStores = async () => {
      try {
        const response = await axiosPrivate.get("/stores");
        setStores(response.data);
        setSelectedStore(response.data[0]);
        getReport(period, response.data[0]);
      } catch (err) {}
    };

    getStores();
  }, []);

  const handleStoreChange = (event) => {
    setSelectedStore(event.target.value);
    setLoadingData(true);
    getReport(period, event.target.value);
  };

  const handlePeriodChange = (event) => {
    setPeriod(event.target.value);
    setLoadingData(true);
    getReport(event.target.value, selectedStore);
  };

  const getReport = async (period, store) => {
    const params = {
      period,
    };

    try {
      const response = await axiosPrivate.get("/reports/recurrent", {
        params,
      });
      setReport(
        response.data.filter((transaction) => transaction.storeId === store.id)
      );
      setLoadingData(false);

      setPieDataAmout(
        pivotData(
          response.data.filter(
            (transaction) => transaction.storeId === store.id
          )
        )
      );
      setPieDataQuantity(
        pivotData2(
          response.data.filter(
            (transaction) => transaction.storeId === store.id
          )
        )
      );
      setPieDataProfit(
        pivotData3(
          response.data.filter(
            (transaction) => transaction.storeId === store.id
          )
        )
      );
    } catch (err) {}
  };

  const pivotData = (data) => {
    let total = 0;
    let recurringClients = [];
    let newClients = [];

    let auxSlicesAmount = [
      {
        clientType: "Recurring Client",
        label: "Clientes Recorrentes",
        value: 0,
        proportion: 0,
        color: "#52B7D8",
      },
      {
        clientType: "New Client",
        label: "Clientes Novos",
        value: 0,
        proportion: 0,
        color: "#E16032",
      },
    ];

    data.forEach((client) => {
      if (client.clientType === "Recurring Client") {
        recurringClients.push(client);
      } else {
        newClients.push(client);
      }
    });
    auxSlicesAmount[0].value = [...new Set(recurringClients)].length;
    auxSlicesAmount[1].value = [...new Set(newClients)].length;
    total = auxSlicesAmount[0].value + auxSlicesAmount[1].value;
    if (total > 0) {
      auxSlicesAmount[0].proportion = auxSlicesAmount[0].value / total;
      auxSlicesAmount[1].proportion = auxSlicesAmount[1].value / total;
    }

    return auxSlicesAmount.filter((slice) => slice.value > 0);
  };

  const pivotData2 = (data) => {
    let total = 0;
    let auxSlicesAmount = [
      {
        clientType: "Recurring Client",
        label: "Clientes Recorrentes",
        value: 0,
        proportion: 0,
        color: "#52B7D8",
      },
      {
        clientType: "New Client",
        label: "Clientes Novos",
        value: 0,
        proportion: 0,
        color: "#E16032",
      },
    ];

    auxSlicesAmount.forEach((slice) => {
      data.forEach((client) => {
        if (slice.clientType === client.clientType) {
          slice.value += client.numberTransactions;
          total += client.numberTransactions;
        }
      });
    });

    auxSlicesAmount.forEach((slice) => {
      slice.proportion = slice.value / total;
    });
    return auxSlicesAmount.filter((slice) => slice.value > 0);
  };

  const pivotData3 = (data) => {
    let total = 0;
    let auxSlicesAmount = [
      {
        clientType: "Recurring Client",
        label: "Clientes Recorrentes",
        value: 0,
        proportion: 0,
        color: "#52B7D8",
      },
      {
        clientType: "New Client",
        label: "Clientes Novos",
        value: 0,
        proportion: 0,
        color: "#E16032",
      },
    ];

    auxSlicesAmount.forEach((slice) => {
      data.forEach((client) => {
        if (slice.clientType === client.clientType) {
          slice.value += client.totalProfit;
          total += client.totalProfit;
        }
      });
    });

    auxSlicesAmount.forEach((slice) => {
      slice.proportion = slice.value / total;
    });
    return auxSlicesAmount.filter((slice) => slice.value > 0);
  };

  const CustomLayerComponent = (customProps) => (layerProps) => {
    const { centerX, centerY, dataWithArc } = layerProps;

    let total = 0;

    dataWithArc.forEach((arc) => {
      total += arc.value;
    });

    return (
      <text
        x={centerX}
        y={centerY}
        textAnchor="middle"
        dominantBaseline="central"
        style={{
          fontSize: "1.5vw",
          fontWeight: "600",
        }}
      >
        {customProps ? USDollar.format(total) : total}
      </text>
    );
  };

  const ColorButton = styled(Button)(() => ({
    color: "white",
    backgroundColor: "#62929E",
    "&:hover": {
      backgroundColor: "#546A7B",
    },
  }));

  const ExportData = (data, period) => {
    const filename = "MSBManager_report.xlsx";
    const periodName = periodOptions.filter(
      (option) => option.value === period
    )[0].label;
    const x = data.map((client) =>
      (({
        name,
        phoneNumber,
        email,
        createdAt,
        totalAmount,
        totalProfit,
        numberTransactions,
        clientType,
      }) => ({
        Cliente: name,
        Telefone: phoneNumber,
        Email: email,
        "Data do Cadastro": createdAt.split(" ")[0],
        "Numero de Transações": numberTransactions,
        "Valor Total (USD)": totalAmount.toFixed(2),
        "Lucro Total (USD)": totalProfit.toFixed(2),
        "Tipo de Cliente": clientType,
      }))(client)
    );
    var ws = XLSX.utils.json_to_sheet(x);
    var wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, `msb_clients_${periodName}`);
    XLSX.writeFile(wb, filename);
  };

  return (
    <Box>
      <Box
        sx={{
          p: 0.5,
          pb: 1,
          display: "grid",
          gap: "10px",
          gridTemplateColumns: "repeat(6, 1fr)",
        }}
      >
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          gridColumn={isNonMobile ? "span 6" : "span 6"}
          flexDirection={isNonMobile ? "row" : "column"}
        >
          <Box
            sx={{
              p: 0.5,
              pb: 1,
              display: "grid",
              gap: "10px",
              gridTemplateColumns: "repeat(2, 1fr)",
            }}
            gridColumn={isNonMobile ? "span 6" : "span 6"}
          >
            <Select
              value={selectedStore}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Selecionar Lojas"
                  placeholder="Add Loja"
                />
              )}
              onChange={handleStoreChange}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              //sx={{ gridColumn: isNonMobile ? "span 1" : "span 3" }}
            >
              {stores.map((store) => (
                <MenuItem id={store.storeId} key={store.id} value={store}>
                  {store.name}
                </MenuItem>
              ))}
            </Select>
            <Select
              value={period}
              label="Período"
              onChange={handlePeriodChange}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              //sx={{ gridColumn: isNonMobile ? "span 1" : "span 3" }}
            >
              {periodOptions.map((period) => (
                <MenuItem id={period.id} key={period.key} value={period.value}>
                  {period.label}
                </MenuItem>
              ))}
            </Select>
          </Box>
          <ColorButton
            variant="contained"
            onClick={() => ExportData(report, period)}
            sx={{ gridColumn: isNonMobile ? "span 1" : "span 3" }}
          >
            <FileDownloadOutlinedIcon sx={{ mr: "10px" }} />
            <Typography fontWeight="bold">EXPORTAR TODOS</Typography>
          </ColorButton>
        </Box>

        <Box
          height="250px"
          gridColumn={isNonMobile ? "span 2" : "span 6"}
          gridRow="span 1"
          backgroundColor={colors.primary[400]}
          sx={{ borderRadius: 2 }}
        >
          <Box
            mt="25px"
            p="0 30px"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h5" fontWeight="600" color={colors.grey[100]}>
              Total de Remessas Feitas
            </Typography>
          </Box>
          <PieChartReport
            data={pieDataQuantity}
            dolar={false}
            CustomLayerComponent={CustomLayerComponent(false)}
          />
        </Box>
        <Box
          height="250px"
          gridColumn={isNonMobile ? "span 2" : "span 6"}
          gridRow="span 1"
          backgroundColor={colors.primary[400]}
          sx={{ borderRadius: 2 }}
        >
          <Box
            mt="25px"
            p="0 30px"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h5" fontWeight="600" color={colors.grey[100]}>
              Total de Clientes
            </Typography>
          </Box>
          <PieChartReport
            data={pieDataAmount}
            dolar={false}
            CustomLayerComponent={CustomLayerComponent(false)}
          />
        </Box>
        <Box
          height="250px"
          gridColumn={isNonMobile ? "span 2" : "span 6"}
          gridRow="span 1"
          backgroundColor={colors.primary[400]}
          sx={{ borderRadius: 2 }}
        >
          <Box
            mt="25px"
            p="0 30px"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h5" fontWeight="600" color={colors.grey[100]}>
              Lucro Total Obtido
            </Typography>
          </Box>
          <PieChartReport
            data={pieDataProfit}
            dolar={true}
            CustomLayerComponent={CustomLayerComponent(true)}
          />
        </Box>
        {report.filter((client) => client.clientType === "New Client").length >
        0 ? (
          <Box
            height="250px"
            gridColumn={isNonMobile ? "span 6" : "span 6"}
            gridRow="span 1"
            backgroundColor={colors.primary[400]}
            sx={{ borderRadius: 2 }}
          >
            <Box
              mt="25px"
              p="0 30px"
              display="flex"
              justifyContent="space-between"
              alignItems="left"
              flexDirection="column"
            >
              <Box
                //mt="25px"
                //p="0 30px"
                mb="25px"
                display="flex"
                justifyContent="space-between"
                alignItems="left"
                //flexDirection="column"
              >
                <Typography
                  variant="h5"
                  fontWeight="600"
                  color={colors.grey[100]}
                  mb="25px"
                >
                  Clientes Novos
                </Typography>
                <ColorButton
                  variant="contained"
                  onClick={() =>
                    ExportData(
                      report.filter(
                        (client) => client.clientType === "New Client"
                      ),
                      period
                    )
                  }
                  sx={{ gridColumn: isNonMobile ? "span 1" : "span 3" }}
                >
                  <FileDownloadOutlinedIcon sx={{ mr: "10px" }} />
                  <Typography fontWeight="bold">EXPORTAR NOVOS</Typography>
                </ColorButton>
              </Box>
              <TableContainer
                component={Paper}
                sx={{ gridColumn: "span 6", maxHeight: 200, magin: 10 }}
              >
                <Table size="small" aria-label="a dense table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="left">
                        <b>Cliente</b>
                      </TableCell>
                      <TableCell align="left">
                        <b>Telefone</b>
                      </TableCell>
                      <TableCell align="left">
                        <b>E-mail</b>
                      </TableCell>
                      <TableCell align="left">
                        <b>Data de Cadastro</b>
                      </TableCell>
                      <TableCell align="left">
                        <b>Número de Transações</b>
                      </TableCell>
                      <TableCell align="left">
                        <b>Valor Total das Transações</b>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {report
                      .filter((client) => client.clientType === "New Client")
                      .map((row) => (
                        <TableRow
                          key={row.id}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell>{row.name}</TableCell>
                          <TableCell>{row.phoneNumber}</TableCell>
                          <TableCell>{row.email}</TableCell>
                          <TableCell>
                            {dayjs(row.createdAt).format("YYYY-MM-DD")}
                          </TableCell>
                          <TableCell>{row.numberTransactions}</TableCell>
                          <TableCell>{`USD ${USDollar.format(
                            row.totalAmount
                          )}`}</TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Box>
        ) : (
          <></>
        )}
        {report.filter((client) => client.clientType === "Recurring Client")
          .length > 0 ? (
          <Box
            height="250px"
            gridColumn={isNonMobile ? "span 6" : "span 6"}
            gridRow="span 1"
            backgroundColor={colors.primary[400]}
            sx={{ borderRadius: 2 }}
            visibility={
              report.filter(
                (client) => client.clientType === "Recurring Client"
              ).length > 0
            }
          >
            <Box
              mt="25px"
              p="0 30px"
              display="flex"
              justifyContent="space-between"
              alignItems="left"
              flexDirection="column"
            >
              <Box
                //mt="25px"
                //p="0 30px"
                mb="25px"
                display="flex"
                justifyContent="space-between"
                alignItems="left"
                //flexDirection="column"
              >
                <Typography
                  variant="h5"
                  fontWeight="600"
                  color={colors.grey[100]}
                  mb="25px"
                >
                  Clientes Recorrentes
                </Typography>
                <ColorButton
                  variant="contained"
                  onClick={() =>
                    ExportData(
                      report.filter(
                        (client) => client.clientType === "Recurring Client"
                      ),
                      period
                    )
                  }
                  sx={{ gridColumn: isNonMobile ? "span 1" : "span 3" }}
                >
                  <FileDownloadOutlinedIcon sx={{ mr: "10px" }} />
                  <Typography fontWeight="bold">
                    EXPORTAR RECORRENTES
                  </Typography>
                </ColorButton>
              </Box>

              <TableContainer
                component={Paper}
                sx={{ gridColumn: "span 6", maxHeight: 200, magin: 10 }}
              >
                <Table size="small" aria-label="a dense table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="left">
                        <b>Cliente</b>
                      </TableCell>
                      <TableCell align="left">
                        <b>Telefone</b>
                      </TableCell>
                      <TableCell align="left">
                        <b>E-mail</b>
                      </TableCell>
                      <TableCell align="left">
                        <b>Data de Cadastro</b>
                      </TableCell>
                      <TableCell align="left">
                        <b>Número de Transações</b>
                      </TableCell>
                      <TableCell align="left">
                        <b>Valor Total das Transações</b>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {report
                      .filter(
                        (client) => client.clientType === "Recurring Client"
                      )
                      .map((row) => (
                        <TableRow
                          key={row.id}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell>{row.name}</TableCell>
                          <TableCell>{row.phoneNumber}</TableCell>
                          <TableCell>{row.email}</TableCell>
                          <TableCell>
                            {dayjs(row.createdAt).format("YYYY-MM-DD")}
                          </TableCell>
                          <TableCell>{row.numberTransactions}</TableCell>
                          <TableCell>{`USD ${USDollar.format(
                            row.totalAmount
                          )}`}</TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Box>
        ) : (
          <></>
        )}
      </Box>
    </Box>
  );
};
export default ReccuringClientsReport;
